<template>
  <section class="digitalMeter">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <el-row :gutter="30">
        <el-col :span="4">
          <div class="tabs">
            <p><span class="title">数字化表计系列</span></p>
            <p class="en">Digital Meter</p>
            <p class="btn"><el-button type="text" :class="showClass ? 'black' : 'grey'" @click="handleTextButtonClick(1)">实现目标</el-button></p>
            <p class="btn"><el-button type="text" :class="showClass ? 'grey' : 'black'" @click="handleTextButtonClick(2)">相关专利</el-button></p>
          </div>
        </el-col>
        <el-col :span="20">
          <div class="main">
            <h1 class="title"><strong>{{ options.title }}</strong></h1>
            <div class="line"></div>
            <ArticleContent :data="options.content" class="information-content" />
          </div>
        </el-col>
      </el-row>
    </section>
  </section>
</template>

<script>

import imageBanner from '../../components/ImageBanner.vue'
import ArticleContent from '../../components/ArticleContent.vue'
export default {
  components: {imageBanner, ArticleContent},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/06-可持续发展.png'),
        slogan: require('../../assets/images/common/slogan.png'),
      },
      options: {title: '', content: []},
      showClass: true,
      id: 1
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      switch (id) {
        case '1':
          this.options = this.$store.getters.getDigitalMeter1 && JSON.parse(JSON.stringify(this.$store.getters.getDigitalMeter1))
          break
        case '2':
          this.options = this.$store.getters.getDigitalMeter2 && JSON.parse(JSON.stringify(this.$store.getters.getDigitalMeter2))
          break
        default:
          this.options = this.$store.getters.getDigitalMeter1 && JSON.parse(JSON.stringify(this.$store.getters.getDigitalMeter1))
          break
      }
    },
    handleTextButtonClick (org) {
      if (org == this.id) return
      this.id = org
      this.showClass = org == 1 ? true : false
      let {...query} = this.$route.query;
      query.id = org
      this.$router.replace({query: {...query}});
    }
  }
}
</script>

<style scoped>
.digitalMeter {background-color: #ecf0f5; padding-bottom: 1rem;}
.container {background-color: #ecf0f5;}
.tabs {text-align:right; line-height: 2;}
.tabs .title {font-size: 2rem; color: #733c38; border-bottom: 1px solid #ccc; font-weight: 600;}
.tabs .en {margin-bottom: 2rem;}
.tabs .btn {text-align: left; padding-left: 2rem;}
.main {background-color: #fff; padding: 2rem 1rem;}
.main .title {text-align: center; font-size: 1.4rem; line-height: 2;}
.main .line {height: 1px; background-color: #e67700; width: 6em; margin: 0 auto 2rem;}
.black {color: rgba(0, 0, 0, 1); font-weight: 600;}
.grey {color: rgba(0, 0, 0, .5);;}

</style>